$(document).ready(() => {

    $('#constructor_refresh').on('click', e => {
        e.preventDefault()

        build();
    })

    $('input.pt, input.pt-par, input.pt-par-v, input[name="constructor_price"]').on('change', e => {
        e.preventDefault()

        resolveCheckboxes()
    })

    $('.select_all_v').on('click', e => {
        e.preventDefault()

        $(`.${$(e.target).data('key-all')}`).prop('checked', true)
    })

    $('.unselect_all_v').on('click', e => {
        e.preventDefault()

        $(`.${$(e.target).data('key-all')}`).prop('checked', false)
    })

})

const build = () => {
    resolveCheckboxes()

    let values = []
    $('.pt-par-v:checked').each((i, el) => {
        values.push($(el).val());
    });

    $.ajax({
        type: "POST",
        url: $('#update_constructor').data('action'),
        data: {
            values: values,
            productId: $('#constructor_product_id').data('id'),
            pricesKey: $('input[name="constructor_price"]:checked').val()
        },
        dataType: 'json',
        beforeSend: () => {
            $('#renders_fields').html('')
            $('#prices_fields').html('')
        },
        success: function (response) {
            notifySuccess(response.message);

            if (response.renderFields) {

                $('#renders_count').html(response.renderFields.length)
                response.renderFields.map((item) => {
                    $('#renders_fields').append(item)
                })
            }

            if (response.pricesFields) {
                response.pricesFields.map((item) => {
                    $('#prices_fields').append(item)
                })
            }
        },
        error: function (xhr, status, err) {
            notifyError(xhr.responseJSON.message);
        }
    });
}

const resolveCheckboxes = () => {
    $('input.pt').each((i, part) => {
        if ($(part).is(':checked')) {
            $(`input.${$(part).attr('id')}-par`).prop('disabled', false)
        } else {
            $(`input.${$(part).attr('id')}-par`).prop('disabled', true).prop('checked', false)
        }
    });

    $('input.pt-par').each((i, parameter) => {

        if ($(parameter).is(':checked')) {
            $(`input.${$(parameter).attr('id')}-v`).prop('disabled', false)
            $(`input#price_${$(parameter).attr('id')}`).prop('disabled', false)
            $(`#all_${$(parameter).val()}`).prop('disabled', false)
        } else {
            $(`input.${$(parameter).attr('id')}-v`).prop('disabled', true).prop('checked', false)
            $(`input#price_${$(parameter).attr('id')}`).prop('disabled', true).prop('checked', false)
            $(`#all_${$(parameter).val()}`).prop('disabled', true)

        }
    });
}
