$(document).ready(function(){

    /*jQuery('input[name="phone"]').inputmask('+375 (99) 999-99-99', {placeholder: "+375 (__) ___-__-__"});*/
    if ($(document).is('.phone__field')) {


        /*$('input[name="working_hours"]').inputmask({
            mask: 'будни hH:mM-hH:mM, выходные hH:mM-hH:mM',
            definitions: {
                "h": {
                    validator: "[0-2]",
                },
                "H": {
                    validator: "[0-9]",
                },
                "m": {
                    validator: "[0-5]",
                },
                "M": {
                    validator: "[0-9]",
                }
            }
        }, {placeholder: "будни __:__-__:__, выходные __:__-__:__"});*/
    }

});
