$(document).ready(function () {
    $('.entity_search_input').on('keyup', event => {
        event.preventDefault();

        const id = $(event.target).data('id')
        const search = $(event.target).val();

        if (search.length < 1) {
            $(`#entity_search_result_${id}`).html('')
            return
        }

        $.ajax({
            type: "POST",
            url: $(event.target).data('action'),
            data: {
                entity: $(event.target).data('entity'),
                search: search,
            },
            dataType: 'json',
            success: (response) => {
                $(`#entity_search_result_${id}`).html('')

                response.items.map(item => {
                    console.log(item)
                    const entitySearch = $(`<div class="entity_search_item" data-id="${id}" data-entity-id="${item.id}" />`)

                    item.fields.map((field, i) => {
                        entitySearch.append(`<span>${field}</span>`)
                        if (i !== item.fields.length - 1) {
                            entitySearch.append(`, `)
                        }
                    })

                    $(`#entity_search_result_${id}`).append(entitySearch)
                })
            },
            error: function () {
                notifyError();
            }
        });
    })

    $(document).on('click', '.entity_search_item', event => {
        event.preventDefault();

        const id = $(event.target).data('id')
        const entityId = $(event.target).data('entityId')
        const entityTitle = $(event.target).text()

        $(`#model_entity_${id}_${entityId}`).remove()

        $(`#model_entities_${id}`)
            .append(
                `<div class="model_entity" id="model_entity_${id}_${entityId}">
                    <input class="d-none" type="text" name="${id}_ids[]" value="${entityId}">
                    <div>${entityTitle}</div>
                    <button class="delete_model_entity btn btn-sm btn-circle btn-danger"
                        data-id="${id}"
                        data-entity-id="${entityId}">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </div>`
            )

    })

    $(document).on('click', '.delete_model_entity', event => {
        event.preventDefault();

        if (!confirm("Вы уверены?")) {
            return;
        }

        const id = $(event.currentTarget).data('id')
        const entityId = $(event.currentTarget).data('entityId')

        $(`#model_entity_${id}_${entityId}`).remove()
    })
});
