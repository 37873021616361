$(document).ready(function () {
    $(document).on('change', '.product_categories_control input[type="checkbox"]', (e) => {
        e.preventDefault()

        let categoriesIds = [];
        $('.product_categories_control input[type="checkbox"]:checked').each(function(){
            categoriesIds.push(this.value);
        });

        $.ajax({
            type: "POST",
            url: $('#product_control').data('action'),
            data: {
                categories_ids: categoriesIds,
                model_id: $('#product_control').data('model-id')
            },
            dataType: 'json',
            success: function (response) {

                $('.attribute_field').each(function(){
                    if (!($(this).data('id') in response.attributes)) {
                        this.remove()
                    }
                });

                $.each(response.attributes, function (index, field) {
                    if (!$('div').is(`#attribute_${index}`)) {
                        $('#product_attributes').append(field)
                    }
                });


                $('.parameter_field').each(function(){
                    if (!($(this).data('id') in response.parameters)) {
                        this.remove()
                    }
                });

                $.each(response.parameters, function (index, field) {
                    if (!$('div').is(`#parameter_${index}`)) {
                        $('#product_parameters').append(field)
                    }
                });
            },
            error: function () {
                notifyError();
            }
        });
    })
})
