$(document).ready(function () {

    $('input.files_field_property').on('change', e => {
        e.preventDefault()

        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                [$(e.target).attr('name')]: $(e.target).val(),
            },
            dataType: 'json',

            success: (response) => {
                notifySuccess(response.message);
            },
            error: () => {
                notifyError();
            }
        })
    })

    $(document).on('change', '.custom-file-input', e => {
        const fileName = document.getElementById($(e.target).attr('id')).files[0].name
        const nextSibling = e.target.nextElementSibling
        nextSibling.innerText = fileName
    })

    $(document).on('change', '.file-input', function() {
        const filesCount = $(this)[0].files.length;
        const textbox = $(this).prev();

        if (filesCount === 1) {
            const fileName = $(this).val().split('\\').pop();
            textbox.text(fileName);
        } else {
            textbox.text(filesCount + ' files selected');
        }
    });
});
